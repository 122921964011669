var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "welcome-discounts" },
    [
      _c("ebsn-meta", {
        staticClass: "description",
        attrs: {
          target: _vm.category,
          path: "category_info.DESCRIPTION",
          tag: "div"
        }
      }),
      _c("h4", { staticClass: "text-uppercase default--text pl-2" }, [
        _vm._v(" " + _vm._s(_vm.$t("coupons.welcomeDiscounts.title")) + " ")
      ]),
      _c(
        "div",
        [
          _c("category-block", {
            staticClass: "category-block category-block-2",
            attrs: { target: _vm.category, position: "position1" }
          })
        ],
        1
      ),
      _c(
        "v-row",
        [
          _vm._l(_vm.activeDiscountCoupons, function(discount, index) {
            return _c(
              "v-col",
              {
                key: "active-discount-" + index,
                staticClass: "coupon-col welcome-style pt-6",
                attrs: { cols: "12", sm: "6" }
              },
              [
                _c("WelcomeCouponCard", {
                  attrs: { coupon: discount, type: _vm.couponType },
                  on: { showDetails: _vm.showActiveCoupon, reload: _vm.reload }
                })
              ],
              1
            )
          }),
          _vm._l(_vm.discountCoupons, function(discount, index) {
            return _c(
              "v-col",
              {
                key: "discount-" + index,
                staticClass: "coupon-col welcome-style pt-6",
                attrs: { cols: "12", sm: "6" }
              },
              [
                _c("WelcomeCouponCard", {
                  attrs: {
                    coupon: discount,
                    type: "incomingDiscount",
                    userPoints: _vm.points
                  },
                  on: { showDetails: _vm.showDetails, reload: _vm.reload }
                })
              ],
              1
            )
          }),
          _c(
            "v-col",
            { staticClass: "pt-6" },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    "x-large": "",
                    rounded: "",
                    block: "",
                    depressed: "",
                    to: "/coupon/ricaricati-di-buoni-info"
                  }
                },
                [_vm._v(_vm._s(_vm.$t("coupons.welcomeDiscounts.moreInfo")))]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }